var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",staticStyle:{"margin":"25px 10px"}},[_c('div',{staticClass:"news"},[_c('div',{staticClass:"news-bg"},[_vm._v(_vm._s(_vm.$t('public.queryBtn')))]),_c('div',{staticClass:"list"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchZc),expression:"searchZc"}],staticClass:"sfz",attrs:{"type":"text","placeholder":_vm.$t('public.keywordQuery')},domProps:{"value":(_vm.searchZc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchZc=$event.target.value}}}),_c('button',{staticClass:"button col button-fill button-large",on:{"click":_vm.searchClick}},[_vm._v(" "+_vm._s(_vm.$t('public.queryBtn'))+" ")]),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.$t('public.noMore')},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index},[_c('router-link',{attrs:{"to":{
              name: 'interest_detail1',
              query: {
                id: item.powerId,
                departId: _vm.departId,
                isSource: 'false',
              },
            }}},[_c('van-cell',{style:({ textAlign: 'left' }),attrs:{"title":item.remark,"is-link":true}})],1)],1)}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }