<template>
  <div name="index">
    <!-- <div class="appealBtn">
		<router-link :to="{ path: '/business_appeal' }">
			<div><van-icon name="description" size="30" color="red" /></div><span>企业诉求</span>
		</router-link>
    </div> -->
    <div class="container" style="margin: 0 10px">
	  
      <Tab :list="tabList" :active="tabActive" @tabChange="tabChange" />
	  <Tab :list="tabList1" :active="tabActive1" @tabChange="tabChange1" />
      <!-- 政策 -->
      <IndexBusinessPolicy ref="policyChild" sign="policy" 
        remark="企业" zcSign="1" v-if="tabActive === 0"
      />
      <!-- 办事指南 -->
      <!-- <IndexDepInterest
        v-show="tabActive === 1"
        :id="getFundInfoId"
        :departId="getFundInfoCode"
        sign="rural"
      /> -->
        <!-- 惠企资金 -->
        <IndexEnterprise
          ref="enterpriseChild"
          sign="enterprise"
          v-show="tabActive === 2"
        />
      <!-- 监督投诉 -->
      <indexHuiqiCapital ref="huiqiCapitalChild" sign="huiqiCapital" 
        remark="监督" zcSign="3" v-if="tabActive === 3"
      />
	  <indexEnterpriseAppeal v-if="tabActive === 4"></indexEnterpriseAppeal>
	  
	  <!-- 政策 -->
	  <IndexTourismPolicy ref="policyChild1" sign="policy" remark="旅游" zcSign="2" v-if="tabActive1 === 0" />
	  <!-- 奖补资金 -->
	  <IndexCapital
	    ref="capitalChild"
	    sign="capital"
	    v-show="tabActive1 === 1"
	  />
	  <!-- 个体工商户 -->
	  <IndividualBusiness
	    ref="individualChild"
	    sign="individual"
	    v-show="tabActive1 === 2"
	  />
      <!-- 底部内容 -->
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "../../components/Footer";
import Tab from "./components/Tab";
// 企业
import IndexEnterprise from "./indexChild/indexEnterprise";
// 政策
import IndexBusinessPolicy from "./indexChild/indexBusinessPolicy";
// 办事指南
import IndexDepInterest from "./indexChild/indexDepInterest";
// 监督投诉
import indexHuiqiCapital from "./indexChild/indexHuiqiCapital.vue";
// 企业诉求
import indexEnterpriseAppeal from "./indexChild/indexEnterpriseAppeal.vue";
// 资金奖补
import IndexCapital from "./indexChild/indexCapital.vue";
// 政策
import IndexTourismPolicy from "./indexChild/indexPolicy";
// 个体工商户
import IndividualBusiness from "./indexChild/indexIndividualBusiness";

export default {
  name: "business_development_index",
  components: { 
	  Tab, 
	  Footer, 
	  IndexEnterprise, 
	  IndexBusinessPolicy, 
	  IndexDepInterest, 
	  indexHuiqiCapital, 
	  indexEnterpriseAppeal, 
	  IndexCapital, 
	  IndexTourismPolicy, 
	  IndividualBusiness 
  },
  activated() {},
  data() {
    return {
      // 标签页
      tabList: [
        { name: this.$t('travel.tabList.policy') },
		{ name: this.$t('travel.tabList.interest') },
        { name: this.$t('travel.tabList.huiqi') },
        { name: this.$t('travel.tabList.enterprise') },
		{ name: this.$t('travel.tabList.appeal') },
      ],
      // 当前选中标签页
      tabActive: 0,
	  // 标签页
	  tabList1: [
	    { name: "旅游政策" },
	    { name: "奖补资金" },
	    { name: "旅游环境监督" },
	  ],
	  // 当前选中标签页
	  tabActive1: -1,
      getFundInfoId: '1',
      getFundInfoCode: ''
    };
  },
  methods: {
    /**
     * 改变选中tab页
     */
    tabChange(index) {
      // console.log(index);
	  this.tabActive1 = -1;
      this.tabActive = index;
	  if(index == 1){
		  window.location.href = 'https://zwfw.xinjiang.gov.cn/xjzwfw2021/xinjiangwork/personalfaren.html'
	  }
    },
	/**
	 * 改变选中tab页
	 */
	tabChange1(index) {
	  console.log(index);
	  this.tabActive = -1;
	  this.tabActive1 = index;
	},
  },
};
</script>
<style scoped>
.container {
  /* height: calc(100vh - 97px); */
}
.swiper-box {
  height: 200px;
  background: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
}
.appealBtn{
	position: fixed;
	bottom: 45px;
	right: 25px;
	background-color: #fff;
	padding: 5px;
	border-radius: 5px;
	z-index: 1;
	border: 1px solid red;
	color: red;
}
</style>
