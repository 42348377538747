<template>
  <div class="nav-tabs" v-if="list.length > 0">
    <a
      v-for="(item, index) in list"
      :key="index"
      href="javascript:void(0);"
      @click="tabChange(index)"
      class="tab-link tab1-link"
      :class="active === index ? 'tab-link-active' : ''"
      :style="customStyle"
    >
      {{ item.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    // tab列表
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    // 当前选中项
    active: {
      type: Number,
      default: 0,
    },
    // 自定义样式
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    tabChange(index) {
      this.$emit("tabChange", index);
    },
  },
};
</script>

<style scoped>
.nav-tabs {
  display: flex;
}

.nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}

.nav-tabs .tab1-link,
.nav-tabs .tab2-link {
  margin-right: 2px;
}

.nav-tabs a {
  /* width: 33%; */
  font-size: 12px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}
</style>
