<template>
  <div name="index">
    <div class="container" style="margin: 25px 10px">
      <div class="area">山坡中心村</div>
      <div class="news">
        <div class="news-bg">查询</div>
        <div class="list">
          <input
            type="text"
            placeholder="请输入关键字"
            class="sfz"
            v-model="searchText"
          />
          <button
            class="button col button-fill button-large"
            v-on:click="searchClick"
          >
            查 询
          </button>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
		  :immediate-check="false"
          @load="onLoad"
        >
          <div v-for="item in org3List" :key="item.id" class="list-box">
            <div class="list-top">
              <span class="title">{{ item.name }}</span>
              <!-- <span></span> -->
              <div class="item-after" @click="show(item.id)">投诉</div>
            </div>
            <div class="content">
              <div class="user">负责人：{{ item.user }}</div>
              <div class="phone">电话号：{{ item.phone }}</div>
            </div>
            <!-- <div><span>时间1：</span>{{ item.time }}</div> -->
            <div class="imglist" v-if="item.certificates">
              <div>营业执照：</div>
              <img
                :src="item.certificates"
                @click="previewImg(item, 'certificates')"
              />
            </div>
            <div class="photo">
              <div>商户环境：</div>
              <div v-if="item.photo" class="photo-list">
                <img
                  :src="`${src}?resize=100x100`"
                  v-for="(src, index) in item.photo.split(',')"
                  @click="previewImg(item, 'photo')"
                  class="org_list_img"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <tousu_individual :moneyId="moneyId" :addsan="addsan" />
  </div>
</template>

<script>
import BASE from "../../../utils/base";
import { ImagePreview } from "vant";
import tousu_individual from "../../../components/tousu_individual.vue";

export default {
  name: "AffairsList",
  components: { tousu_individual },
  data() {
    return {
      org3List: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      searchText: "",
      addsan: true,
      moneyId: "",
      loading: false,
      finished: false,
      param: {},
    };
  },
  methods: {
    async searchClick() {
	  this.org3List = [];
      this.loading = false;
      this.pageNo = 1;
      if (this.searchText != "") {
        this.param = { name: "*" + this.searchText + "*" };
      } else {
        this.param = {};
      }
	  if(this.finished){
		  this.finished = false;
	  }else{
		  this.onLoad();
	  }
	  
    },
    onLoad() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.param,
      };

      const url = "zxSuperviseEnterprise/list";

      this.axios
        .get(BASE.genUrl(url, params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;

          if (data.code !== 200) this.finished = true;

          if (data.result) {
            this.org3List = [...this.org3List, ...data.result.records];
            this.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
        });
    },
    trim(str, all = false) {
      return all ? str.replace(/\s+/g, "") : str.replace(/(^\s*)|(\s*$)/g, "");
    },
    previewImg(record, key) {
      console.log("record:", record, "key:", key);
      const images = record[key].split(",").map((item) => this.trim(item));
      ImagePreview({ images, closeable: true });
    },
    show(id) {
      this.moneyId = id;
      this.$modal.show("individual_modal");
    },
    hide() {
      this.$modal.hide("individual_modal");
    },
  },
  async activated() {
    this.pageNo = 1;
	this.org3List = [];
    this.onLoad();
  },
};
</script>

<style scoped>
.list-box .tousu_btn {
  display: block;
  float: right;
  border: 1px solid #b51414;
  padding: 0.1rem;
  color: #b51414;
}
.list-box .money {
  color: red;
  font-weight: bold;
}
.list-box span {
  color: #ccc;
}
.list-box .title {
  font-size: 14px;
  color: black;
  font-weight: bold;
}
.list-box .content {
  display: flex;
  width: 100%;
  position: relative;
  margin: 2px 0;
  font-size: 14px;
}
.list-box .photo {
}
.list-box .photo .photo-list {
  display: flex;
  overflow-x: auto;
}
.list-box img {
  width: 100px;
  height: 100px;
}
.content .phone {
  position: absolute;
  right: 0;
}
a.disabled {
  color: #ccc;
}

.data-table-pagination {
  font-size: 14px;
  padding: 1rem;
}

.data-table-pagination a {
  padding: 0 1rem;
}

.detail h3 {
  color: #fff;
  font-size: 16px;
}

.detail h4 {
  color: #fff;
  font-size: 14px;
}

.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}

.detail img {
  width: 100%;
}

.zclist li {
  text-align: left;
}

.zclist li a {
  color: #fff;
  font-size: 14px;
}

.item-content {
  padding: 0.5rem 0;
}

.item-inner {
  display: flex;
  flex-direction: row;
}

.item-title {
  padding-right: 0.1rem;
  font-size: 14px;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-after {
  width: 15%;
}

.tab {
  display: none;
}

.tab-active {
  display: block;
}

#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
}

#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
}

#tab3 .item,
#tab2 .item {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
}

#tab2 .item .l {
  width: 50%;
  display: block;
}

#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}

#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}

#tab2 .item .r div {
  padding: 0 0.2rem;
}

#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}

#tab2 .info-div,
#tab3 .info-div {
  width: 50%;
  margin-right: 0.1rem;
  margin-top: 20px;
}

#tab2 .info-title,
#tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}

#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}

#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}

#tab2 .item,
#tab3 .item {
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}

.list ul:before {
  background: none;
}

.list .zhengce-select {
  display: flex;
  flex-direction: row;
}

.list .zhengce-select-div {
  width: 100%;
}

.list .zhengce-select-div select {
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}

.news-bg {
  background: url("../../../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}

.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}

.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}

.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}

.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}

.container .nav-tabs {
  display: flex;
}

.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}

.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}

.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
.container .area {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: -10px;
}
.container button {
  color: #fff;
  width: 80%;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
  margin-top: -5px;
  margin-bottom: 20px;
  border: 0;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  text-align: center;
}
.container .news button,
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.container .tabs .tab-active {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}

.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}

.list-box {
  border: 1px solid #ccc;
  font-size: 12px;
  text-align: left;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

.imglist {
  overflow-x: hidden;
  white-space: nowrap;
  margin: 5px 0;
}
.org_list_img {
  width: 33.3333%;
  display: inline-block;
}
.list-top {
  position: relative;
}
.item-after {
  width: 15%;
  height: 16px;
  border: 1px solid #b51414;
  padding: 0.1rem;
  color: #b51414;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0px;
}
</style>
