<template>
  <div class="container" style="margin: 25px 10px">
    <div class="news">
      <div class="news-bg">{{ $t('public.queryBtn') }}</div>
      <div class="list">
        <input type="text" class="sfz" v-model="searchZc"
          :placeholder="$t('public.keywordQuery')"
        />
        <button
          class="button col button-fill button-large"
          v-on:click="searchClick"
        >
          {{ $t('public.queryBtn') }}
        </button>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.noMore')"
          @load="onLoad"
        >
          <div v-for="(item, index) in list" :key="index">
            <router-link
              :to="{
                name: 'interest_detail1',
                query: {
                  id: item.powerId,
                  departId: departId,
                  isSource: 'false',
                },
              }"
            >
              <van-cell
                :title="item.remark"
                :is-link="true"
                :style="{ textAlign: 'left' }"
              />
            </router-link>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import BASE from "../../../utils/base";
export default {
  name: "indexDepInterest",
  components: {},
  activated() {
    this.list = [];
    this.onLoad();
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "",
    },
    departId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchZc: "",
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      // 展示列表
      list: [],
    };
  },
  watch: {
    id() {
      this.finished = false;
      this.list = [];
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };
      this.loading = true;
      this.onLoad();
    },
  },
  methods: {
    async searchClick() {
      this.list = [];
      this.loading = false;
      this.requestData.pageNo = 1;
      if (this.searchText != "") {
        this.param = { skey: this.searchZc };
      } else {
        this.param = {};
      }
      if (this.finished) {
        this.finished = false;
      } else {
        this.onLoad();
      }
    },
    // 数据加载
    onLoad() {
      console.log("indexDepInterest - onLoad -----------");
      // 请求参数
      const params = {
        ...this.requestData,
        ...this.param,
        // sysOrgCode: this.$props.id
        // depId: "",
      };

      const url = "zxSuperviseDeppower/listcodes";
      //const url = "/zxSupervisePowerlist/list";

      this.axios
        .get(BASE.genUrl(url, params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;

          if (data.code !== 200) this.finished = true;

          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>
<style scoped>
.collapse-item-title {
  text-align: left;
}
.custom-title {
  border: 1px solid red;
}
.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}
.news-bg {
  background: url("../../../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
  text-align: left;
  padding: 0.8rem;
}

.list ul:before {
  background: none;
}
.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
.list button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
</style>
