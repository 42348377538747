import { render, staticRenderFns } from "./indexHuiqiCapital.vue?vue&type=template&id=5ea6c5ae&scoped=true&"
import script from "./indexHuiqiCapital.vue?vue&type=script&lang=js&"
export * from "./indexHuiqiCapital.vue?vue&type=script&lang=js&"
import style0 from "./indexHuiqiCapital.vue?vue&type=style&index=0&id=5ea6c5ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ea6c5ae",
  null
  
)

export default component.exports