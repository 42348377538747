<template>
	<div name="index">
		<div class="container">
			<div style="margin: 20px 0;">
				<box title="企业诉求">
				  <div style="width: 100%;">
				    <van-form validate-first @submit="bindSubmit" label-align="right" ref="form">
				      <van-field name="enterpriseName" label="企业名称" placeholder="请填写企业名称"
				        v-model="form.enterpriseName" :rules="rules.enterpriseName" :required="true"
				      />
				
					  <van-field name="socialCreditCode" label="社会信用代码" placeholder="请填写社会信用代码"
				        v-model="form.socialCreditCode" :rules="[{ validator: checkSocialCreditCode, message: '社会信用代码格式不正确' }]"
				      />
					  
					  <van-field name="name" label="提交姓名" placeholder="请填写提交姓名"
					    v-model="form.name"
					  />
					  
					  <van-field name="身份证号" label="身份证号" placeholder="请填写身份证号"
					    v-model="form.idCard" :rules="[{ validator: checkIdcard, message: '请填写有效证件号码' }]"
					  />
				
				      <van-field name="phone" type="tel" label="联系电话" placeholder="请填写联系电话"
				        v-model="form.phone" :rules="[{ validator: checkPhone, message: '请填写有效证件号码' }]"
				      />
				      
				      <van-field v-model="form.content" type="textarea"
				        name="content" rows="8" label="诉求描述" placeholder="请填写诉求描述"
				      />
				
				      <div style="margin: 16px;display: flex;">
				        <van-button round block type="danger" native-type="submit"> {{ $t('public.submit') }} </van-button>
						<!-- <van-button round block type="danger" native-type="button" @click="goBack"> {{ $t('public.goBack') }} </van-button> -->
				      </div>
				    </van-form>
				  </div>
				</box>  
			</div>
		</div>
	</div>
</template>

<script>
import BASE from "@/utils/base";
import Box from '../../supervise/components/Box';
import { IdCardValid } from "@/utils/IdCardValid";
import Footer from "@/components/Footer";
// ali oss
let OSS = require('ali-oss');

export default {
  name: 'EnterpriseAppeal',
  components: { Box, Footer },
  data() {
    return {
      form: {
        
      },

    }
  },
  watch: {
    
  },
  computed: {
    rules() {
      
      return {
        enterpriseName: [{ required: true, message: '请填写企业名称' }],
      }
    }
  },
  activated() {
    this.form = {
      enterpriseName: '',      // 企业名称
      socialCreditCode: '',          // 社会信用代码
      name: '',       // 提交姓名
      idCard: '',//身份证号
      phone: '', // 联系电话
      content: '', // 诉求描述
    }
  },
  mounted() {
  },
  methods: {
	// 验证身份证号
	checkIdcard(value) {
		if(value) {
			if (value.length === 18) return IdCardValid(value)
			return false
		}
	},
	// 验证社会信用代码
	checkSocialCreditCode(value) {
		if(value) {
			if(/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)){
				return true;
			}else{
				return false;
			}
		}
	},
	// 验证手机号
	checkPhone(value) {
		if(value) {
			if(/^1[3456789]\d{9}$/.test(value)) {
				return true;
			}else{
				return false;
			}
		}
	},
    bindSubmit(e) {
      const that = this

      this.axios
        .post(BASE.genUrl("zxSuperviseAppeal/add", {}), this.form)
        .then(async res => {
          const { code = 201, message = '' } = res.data
          if (code == 200) {
            that.$Toast({
              message: '操作成功',
              // onClose: () => that.$router.go(-1)
            });
          } else {
            that.$Toast(message);
          }
        })

    },
	goBack() {
		this.$router.go(-1);
	}
  }
}
</script>

<style lang="less" scope>
  .content {
    padding: 16px;
    text-align: left;
  }
  .notice-title {
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
  }
  .notice-content {
    font-size: 14px;
    line-height: 1.8;

    &__title, &__text > p {
      margin-bottom: 10px;
    }

    &__title {
      font-weight: 600;
    }
  }

  .agree{
    font-weight: 600;
    margin-bottom: 16px;
  }
</style>
