<template>
  <modal
    name="business_modal"
    width="95%"
    left="0"
    height="80%"
    class="modal"
    @before-close="beforeClose"
  >
	  <div style="overflow-y: auto;height: 100%;">
		  <h3 style="margin-top: 1rem">第一步：选择负面行为类型</h3>
		  <!-- <select v-model="tousuType">
		    <option>本人或者纵容、默许配偶、子女及其配偶和其他特定关系人从企业谋求利益，以及违反规定与管辖的区域或者业务范围企业发生可能影响公正执行公务的经营活动</option>
		    <option>无领取资格</option>
		    <option>重复发放</option>
		    <option>数据有误</option>
		    <option>其他情况</option>
		  </select> -->
		  <van-radio-group v-model="tousuType" style="margin: 5px 1.25rem;line-height: 1.25rem;">
		    <van-radio name="本人或者纵容、默许配偶、子女及其配偶和其他特定关系人从企业谋求利益，以及违反规定与管辖的区域或者业务范围企业发生可能影响公正执行公务的经营活动">本人或者纵容、默许配偶、子女及其配偶和其他特定关系人从企业谋求利益，以及违反规定与管辖的区域或者业务范围企业发生可能影响公正执行公务的经营活动</van-radio>
		    <van-radio name="收受企业及其负责人赠送可能影响公正执行公务的礼品、礼金、消费卡和有价证券、股权、其他金融产品等财务,借与企业交往之机或者以担任顾问、提供中介服务为名向企业及其负责人收取咨询费或者索取其他好处">收受企业及其负责人赠送可能影响公正执行公务的礼品、礼金、消费卡和有价证券、股权、其他金融产品等财务,借与企业交往之机或者以担任顾问、提供中介服务为名向企业及其负责人收取咨询费或者索取其他好处</van-radio>
		    <van-radio name="以本人、配偶、子女及其配偶和其他特定关系人名义向企业筹资、借款、借车、借房，影响公正执行公务，无偿或者象征性地支付报酬接受企业服务或使用劳务，由企业及其负责人支付应由单位或者个人负担的费用">以本人、配偶、子女及其配偶和其他特定关系人名义向企业筹资、借款、借车、借房，影响公正执行公务，无偿或者象征性地支付报酬接受企业服务或使用劳务，由企业及其负责人支付应由单位或者个人负担的费用</van-radio>
		    <van-radio name="接受企业及其负责人提供的可能影响公正执请或者旅游、健身、娱乐等活动安排，违反有关有、使用企业赠送的健身卡、消费卡，或者接人邀请，违规出入私人会所">接受企业及其负责人提供的可能影响公正执请或者旅游、健身、娱乐等活动安排，违反有关有、使用企业赠送的健身卡、消费卡，或者接人邀请，违规出入私人会所</van-radio>
		    <van-radio name="以本人、配偶、女及其配偶和其他特定关股或者持有非上市企业股份或者证券，违反规定安排配偶、子女及其配偶和其他特定关系人到企业担任高级职务或者挂名领薪、兼职取酬">以本人、配偶、女及其配偶和其他特定关股或者持有非上市企业股份或者证券，违反规定安排配偶、子女及其配偶和其他特定关系人到企业担任高级职务或者挂名领薪、兼职取酬</van-radio>
		    <van-radio name="违规干预和插手市场经济活动，为企业及其负责人谋取不正当利益">违规干预和插手市场经济活动，为企业及其负责人谋取不正当利益</van-radio>
		    <van-radio name="违规干预和插手企业生产经营活动，妨碍企业健康发展，损害企业合法权益">违规干预和插手企业生产经营活动，妨碍企业健康发展，损害企业合法权益</van-radio>
		    <van-radio name="在审批监管、资源开发、金融信贷、大宗采购、土地使用权出让、房地产开发、工程招投标等环节利用职权吃拿索贿受贿，对企业乱检查、乱摊派、乱收费、乱罚款、乱募捐">在审批监管、资源开发、金融信贷、大宗采购、土地使用权出让、房地产开发、工程招投标等环节利用职权吃拿索贿受贿，对企业乱检查、乱摊派、乱收费、乱罚款、乱募捐</van-radio>
		    <van-radio name="在政务服务活动中不作为、慢作为、假作为、乱作为,对涉及企业利益问题依照政策或者规定能解决而不及时解决,或者对符合政策的诉求消极应付、推诿扯皮">在政务服务活动中不作为、慢作为、假作为、乱作为,对涉及企业利益问题依照政策或者规定能解决而不及时解决,或者对符合政策的诉求消极应付、推诿扯皮</van-radio>
		    <van-radio name="其他违反党规党纪和法律法规的情形">其他违反党规党纪和法律法规的情形</van-radio>
		  </van-radio-group>
		  <h3 style="margin-top: 1rem">第二步：输入手机号获取验证码</h3>
		  <input
		    type="text"
		    placeholder="输入手机号"
		    class="yzm-input"
		    v-model="phone"
		  />
		  <br />
		  <button
		    @click="getYzm"
		    class="yzm-button"
		    v-bind:class="{ disabled: count_over }"
		    :disabled="count_over"
		  >
		    {{ yzm_text }}
		  </button>
		  <div v-if="issend">
		    <h3 style="margin-top: 1rem">第三步：输入刚获取到的验证码</h3>
		    <input type="text" placeholder="输入验证码" v-model="yzm" />
		    <button @click="checkYzm" class="button">提交</button>
		    <button @click="hide" class="button gray" style="margin-top: 0.5rem">
		      取消
		    </button>
		  </div>
	  </div>
  </modal>
</template>
<script>
import BASE from "../utils/base";

export default {
  name: "tousu_modal",
  components: {},
  props: ["moneyId", "addsan"],
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    resultList: [],
    phone: "",
    yzm: "",
    issend: false,
    tousuType: "金额不对",
    count_over: false,
    timer: null,
    yzm_text: "获取验证码",
  }),
  watch: {},
  methods: {
    hide() {
      this.$modal.hide("business_modal");
      this.issend = false;
      this.count_over = false;
    },
    beforeClose() {
      this.issend = false;
      this.count_over = false;
      this.phone = "";
    },
    getYzm() {
      const reg = /^1[3456789]\d{9}$/;
      if (reg.test(this.phone)) {
        this.axios
          .post(BASE.genUrl("phoneCode/send_msg", { phone: this.phone }))
          .then(() => {
            this.issend = true;
            this.count_over = 60;
            this.timer = setInterval(this.countdown, 1000);
            alert("验证码已发送！");
          });
      } else {
        this.$Toast("请输入正确的手机号码");
      }
    },
    countdown() {
      if (this.count_over > 1) {
        this.count_over -= 1;
        this.yzm_text = "重新获取验证码（" + this.count_over + "s）";
      } else {
        this.count_over = false;
        this.yzm_text = "获取验证码";
        clearInterval(this.timer);
      }
    },
    checkYzm() {
      let that = this;
      this.axios
        .post(
          BASE.genUrl("phoneCode/verify_msg", {
            phone: this.phone,
            code: this.yzm,
          })
        )
        .then((res) => {
          console.log(res);
          if (!res.data.success) {
            alert(res.data.message);
            return false;
          }
          that.submitData();
        });
    },
    submitData() {
      // let data = "DpHxKkMbPymRYCFi" + new Date().getTime();
      // let _data = CryptoJS.encrypt(data);

      let that = this;
      this.axios
        .post(
          BASE.genUrl("zxSuperviseLetter/" + (this.addsan ? "addsan" : "add")),
		  {
		    phone: this.phone,
		    moneyId: this.moneyId,
		    departId: "786157ea22e746c6a6e751039c7a962b",
		    content: this.tousuType,
		    remark: "其他",
		  }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            alert(res.data.message);
            that.phone = "";
            that.yzm = "";
            that.hide();
          } else {
            alert(res.data.message);
            that.phone = "";
            that.yzm = "";
            that.issend = false;
            this.count_over = false;
          }
        });
    },
  },
};
</script>
<style scoped>
/deep/ .van-radio__label{
	margin-top: 0.625rem;
}
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.modal {
  z-index: 9999999;
}

.source a {
  color: #fff;
}

.modal h3 {
  margin-bottom: 0.5rem;
}

.button {
  display: block;
  background: #b51414;
  color: #fff;
  border: 0;
  padding: 0.5rem 2rem;
  margin: 1rem auto 0rem;
  border-radius: 0.5rem;
  width: 50%;
  font-size: 16px;
}

.modal {
  /* text-align: center; */
  font-size: 16px;
  border-radius: 0.5rem;
  /* padding: .5rem; */
}

.modal input {
  /* text-indent: .2rem; */
  padding: 0.5rem 1rem;
  font-size: 16px;
  width: 50%;
}

.yzm-input {
  /* border: 1px solid #999;
  border-radius: .5rem;
  border-right: 0; */
}

.yzm-button {
  padding: 0.5rem 1rem;
  background: #b51414;
  color: #fff;
  border: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  font-size: 16px;
  /* width: 50%; */
  margin-bottom: 0.5rem;
}

.disabled {
  background: #ccc;
  color: #b51414;
}

.gray {
  background: #ccc;
  color: #fff;
}

.modal select {
  /* width: 50%; */
  font-size: 14px;
  text-align: center;
  padding: 0.5rem 1rem;
  /* margin-top: 1rem; */
}

.detail-footer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.detail-footer div {
  width: 50%;
}

.detail-footer div.r {
  text-align: right;
}

.detail-footer div.r a {
  border: 1px solid #b51414;
  color: #b51414;
  padding: 0.1rem 0.5rem;
}

.personInfo {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.5rem;
}

.footer {
  padding: 0.5rem 0;
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.detail {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: left;
}

.personInfo span {
  color: #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.info-title {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

h3 {
  margin-bottom: 0.3rem;
}

.detail p {
  font-size: 14px;
  line-height: 25px;
}

.detail li {
  margin: 0 0.5rem;
  border-bottom: 1px solid #ccc;
}
select {
	-webkit-appearance: none;  /* 移除默认样式 */
	  -moz-appearance: none;
	  -o-appearance: none;
	  appearance: none;
	  padding: 5px;  /* 内边距 */
	  font-size: 16px;  /* 字体大小 */
	  width: 200px;  /* 宽度 */
	  overflow: hidden !important;  /* 超出部分隐藏 */
	  text-align: left;  /* 文字对齐 */
	  /* 关键是这个属性，允许长文本自动换行 */
	  white-space: normal; 
}
</style>
